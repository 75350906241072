import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "../../components/Typography/Typography";
import MainLayout from "../../components/mainlayout";
import Summary from "../../components/CaseStudy/Summary/Summary";
import { StaticImage } from "gatsby-plugin-image";
import { mobileViewBreakpoint } from "../../components/helper";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: "0px -16px"
  },
  caseStudyContainer: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 0px auto",
    "& > h2": {
      marginBottom: "28px",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 16px 28px 16px",
      margin: "80px auto 0px auto"
    }
  }
}));

const Article = () => {
  const classes = useStyles();
  const theme = useTheme();

  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(
        relativePath: { eq: "casestudy/bannerdakarrally.jpg" }
      ) {
        childImageSharp {
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <MainLayout
      title={"Dubai Parks and Resorts"}
      banner={
        <StaticImage
          src="../../assets/images/casestudy/adddriyah.jpg"
          draggable="false"
          alt="white travel bus"
        />
      }
    >
      <div className={classes.mainContainer}>
        <div className={classes.caseStudyContainer}>
          <Typography customVariant={"h2Bold"}>
            Dubai Parks and Resorts
          </Typography>

          <Typography customVariant={"h5Regular"}>
            <p>
              Our team created one of the region's most successful and most
              significant parking, traffic, and transport solutions for a theme
              park.
            </p>
            <p>
              In 2015, MOTUS | ONE team members headed up the transport division
              at Dubai Parks and Resort and were fully integrated within the
              projects and operations activities.
            </p>
            <p>
              Our remit was broad – from developing division - wide budgets and
              creating scope for tenders to formulating staffing rosters, FF&E
              requirements and creating the policies and procedures. We
              delivered a system that is still in place today.
            </p>
          </Typography>
        </div>
        <Summary
          title={"Overview"}
          fullHeight={false}
          isFlipped={false}
          image={
            <StaticImage
              src="../../assets/images/casestudy/F1_Case_Study_Overview_Image.jpg"
              draggable="false"
              alt="prople waiting for transport"
            />
          }
          link={undefined}
          description={
            <>
              <p>
                The initial phase included integrating with the project team to
                re - design existing car parks. We followed this with intensive
                reporting, and calculations against traffic - impact studies to
                show probable flow rates on peak and off - peak days.
              </p>

              <p>
                <b>The highlights of our deliverables include:</b>
                <ul>
                  <li>A master traffic management and car parking ops plan </li>
                  <li>Custom - built transport tender packages </li>
                  <li>
                    Specification of the overall traffic and transport policy
                    and procedures
                  </li>
                  <li>Risk assessments, staffing levels and welfare policy </li>
                  <li>Customer - service and operational training manuals</li>
                </ul>
                To accommodate the unique demands of the venue and region, we
                designed a valet parking program within the VIP car park. Guests
                were validated through existing entry gates via integrated POS
                systems and a robust vehicle inspection process. We also
                trialled a number of digital technologies to enhance the guest
                experience while improving service levels.
              </p>
            </>
          }
        />

        <Summary
          title={""}
          fullHeight={false}
          image={
            <StaticImage
              src="../../assets/images/casestudy/F1_Case_Study_Elements_Image.jpg"
              draggable="false"
              alt="car parking bird view"
            />
          }
          link={undefined}
          description={
            <p>
              <b>Management</b>
              <p>
                It was critical from early on to involve industry experts and
                local resources to participate in the review and planning cycle.
                This regional expertise enabled us to feel confident in our
                ability to face the challenges ahead. Over the next six months,
                we built the core transport team using this methodology and
                created what would become the A - team.
              </p>
              <b>Challenges</b>
              <p>
                The forecast of car - park volume varied significantly
                throughout the year, and the disparity in estimated and actual
                attendance levels complicated the process. The forecasting
                system then in place led to significant forecast errors, either
                overestimating or underestimating the number of vehicles on
                site.
              </p>
              <b>Solutions</b>
              <p>
                We needed to revisit the master plan on existing car parks and
                entry points, then implement best practices within the theme
                park industry. In doing this, we completely redeveloped the car
                parks into speed - parking configurations while improving the
                entry and exit points to accommodate the flow matrix. Our design
                has since been used by Expo 2020 as an example of best practice.
              </p>
            </p>
          }
        />
      </div>
    </MainLayout>
  );
};

export default Article;
